import React from "react";
import { css } from "@emotion/core";
import { ScaleLoader } from "react-spinners";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "reactstrap";
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
import SubHeading from "../alter3/SubHeading.jsx";

const isMobile = window.innerWidth <= 500;





const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

require("dotenv").config();

const showProfile = process.env.REACT_APP_SHOW_PROFILE + "";

const phpserver =
  process.env.REACT_APP_PROXY + process.env.REACT_APP_LINKEDIN_CALLBACK;

class Career extends React.Component {
  constructor(props) {
    super(props);
    const restoredLinkedin_id = localStorage.getItem("linkedin_id");

    this.state = {
      code: "",
      errorMessage: "",
      access_token: "",
      first_name: "",
      last_name: "",
      linkedin_id: restoredLinkedin_id,
      profile_picture: "",
      time_out: "",
      loading: false,
      disabled: false,
      file: "",
      formPostbackMessage: "",
      submittedClass: " ",
      displayLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFailure = (error) => {
    this.setState({ code: "", errorMessage: error.errorMessage });
    this.setState({ displayLoading: false });
  };

  text = {
    title: "Careers",
    subtitle: "Quer fazer parte do nosso time?",
  };

  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData();
    this.setState({ formPostbackMessage: "" });
    this.setState({ displayLoading: true });
    // loading
    this.setState({
      loading: true,
      actionBtns: true,
    });

    data.append("action", "addByForm");
    data.append("linkedin_id", localStorage.getItem("linkedin_id"));
    data.append("nomecompleto", this.nomecompleto.value);
    data.append("email", this.email.value);
    data.append("github", this.github.value);
    data.append("file", this.arquivo.files[0]);

    fetch(phpserver + "user_registration.php", {
      // mode: "no-cors",
      method: "POST",
      body: data,
    }).then((response) => {
      response.json().then((responseJson) => {
        if (responseJson[0] === "success") {
        } else {
          this.setState({ formPostbackMessage: responseJson[1] });

          return false;
        }
      });
    });
  }

  render() {
    const { code, errorMessage, formPostbackMessage } = this.state;

    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}

          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          <section className="section why-choose-us ">
            {!code && (
              <Container className="bring-to-front ">
                <div className="shapes-container ">
                  <div className="pattern pattern-dots " />
                            </div>


                <div
                  className={
                    this.state.displayLoading ? "sweet-loading" : "hiddenDiv"
                  }
                >
                  <ScaleLoader
                    css={override}
                    size={150}
                    //size={"150px"} this also works
                    color={"#073039"}
                    loading="true"
                    // {this.state.loading}
                  />
                </div>
                <div
                  className={
                    this.state.displayLoading
                      ? "hiddenDiv"
                      : "container pb-8 bring-to-front lessMarginTop actionBtns"
                  }
                            >
                                
                                <div className="row gap-y">
                    
                    <div className="col-md-7">




                        
          {/*              
                    


  <p className="text-muted lead mx-auto">
                        <strong>No momento estamos sem oportunidades ou vagas abertas.</strong><br /><br />
                      </p>

                      <p className="text-muted lead mx-auto">
Se você tem interesse em trabalhar com o time da Up Outsourcing, envie seu curriculo para <strong>rh@upoutsourcing.com</strong> para futuras oportunidades!
</p>
<br/> 

*/}


                      <p className="text-muted lead mx-auto">
                        <strong>Confira nossas oportunidades:</strong><br /><br />
                      </p>


                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                <strong>  <a href="/vagas/fullstackphpsr">FullStack SR  (React/Node) </a></strong>
                                </div>
                              </div>
                              <div className="card-body ">
                              Estamos em busca de uma pessoa com sólidos conhecimentos em NodeJs e React, para atuar em projeto internacional, com o desenvolvimento de uma plataforma voltada para automatização de processos com IA.
                       <br />
                                <br />
                               
                                  <a href="/vagas/fullstackphpsr">Mais Detalhes..</a>
                               
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                <strong>  <a href="/vagas/backendnode">Backend Developer: Node.JS </a></strong>
                                </div>
                              </div>
                              <div className="card-body ">
                              Estamos em busca de uma pessoa que será esponsável por coletar dados e sites e ou sistemas diversos.
                              
                                <br />
                                <br />
                                
                                  <a href="/vagas/backendnode">Mais Detalhes..</a>
                              
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>



                      
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                <strong>  <a href="/vagas/backendsenior"> Backend Developer - Sênior</a></strong>
                                </div>
                              </div>
                              <div className="card-body ">  
                              Procuramos uma pessoa para fazer parte de uma equipe de engenharia para atuar no desenvolvimento e arquitetura de uma plataforma de viagens. <br />
                                <br />
                               
                                  <a href="/vagas/backendsenior">Mais Detalhes..</a>
                               
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>


 
                      
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                <strong> <a href="/vagas/frontendreact">Frontend Developer: React</a></strong> 
                                </div>
                              </div>
                              <div className="card-body ">
                              Estamos em busca de uma pessoa que será responsável pelo site da empresa e plataforma que os clientes acessam. Também irá transformar mockups em telas ou páginas no sistema.   <br />
                                <br />
                            
                                  <a href="/vagas/frontendreact">Mais Detalhes..</a>
                               
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
 

                     
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                               <strong>   <a href="/vagas/virtualassistant">Virtual Assistant</a></strong>
                                </div>
                              </div>
                              <div className="card-body ">
                              Estamos em busca de uma pessoa que irá atuar de forma remota no atendimento a clientes em diversos canais digitais.
                                <br />
                                <br />
                               
                                  <a href="/vagas/virtualassistant">Mais Detalhes..</a>
                               
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>






                 

                        
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/seniorelixir">Sênior Backend Engineer (Elixir)</a>
                                </div>
                              </div>
                              <div className="card-body ">
                              Procuramos uma pessoa para fazer parte de uma equipe de engenharia, para atuar no desenvolvimento e arquitetura de uma plataforma de viagens.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/seniorelixir">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                  
                      



                     
                   {/*
                      
                    
                    
                   
                  
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/fullstack">FullStack Developer Pl.|Sr.</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                Estamos em busca de FullStak Developers para atuarem no projeto de um grande cliente do ramo de gerenciamento de benefícios empresariais dos EUA, desenvolvendo novas funcionalidades.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/fullstack">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/drupal">Drupal CMS</a>
                                </div>
                              </div>
                              <div className="card-body ">
                              Estamos em busca de uma pessoa com sólidos conhecimentos em desenvolvimento backend para atuar em um time internacional de uma empresa de grande porte do ramo de turismo especializada em cruzeiros.
                              <br />
                                <br />
                                <strong>
                                  <a href="/vagas/drupal">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>



    



    <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/tracking">Tracking Analyst</a>
                                </div>
                              </div>
                              <div className="card-body ">
                              Buscamos uma pessoa para atuar como Tracking Analyst em um projeto norte-americano do ramo de hotelaria e turismo para monitorar e analisar dados de rastreamento e fazer integrações de front-end.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/tracking">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>



                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/scrummaster">Scrum Master</a>
                                </div>
                              </div>
                              <div className="card-body ">
                              Buscamos uma pessoa com sólida experiência em metodologias ágeis (especialmente em Scrum) que será responsável por facilitar o desenvolvimento de maneira Ágil, garantindo a entrega eficaz de produtos de alta qualidade.
                        <br />
                                <br />
                                <strong>
                                  <a href="/vagas/scrummaster">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>


                      
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/datascience">Data Science Engineer</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                Estamos 
                                em busca de pessoas que
                                 possam projetar, desenvolver e manter
                                  pipelines de dados, criar visualizações e dashboards, criar modelos preditivos
                                   e melhorar sistemas de recomendação para apoiar a tomada de decisões baseadas em dados.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/datascience">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/qa">Analista de Qualidade</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                Buscamos pessoas com experiência em Testes de Qualidade!
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/qa">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>





                     
                        
                        

                        <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/phppost"> PHP Developer Pl.|Sr.</a>
                                </div>
                              </div>
                              <div className="card-body ">
                               Estamos ampliando nosso time da UP e buscamos PHP Developers com foco no backend para desenvolver novas funcionalidades em dois produtos de um grande cliente do ramo de gerenciamento de benefícios empresariais dos EUA.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/phppost">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                     

   <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/frontreactlms">React Frontend</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                Buscamos alguém que atue com React para contribuir na criação de um novo projeto. Um LMS (learning management system) para o setor de gerenciamento de benefícios empresariais.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/frontreactlms">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>



                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/frontreactlms">React Frontend</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                Buscamos alguém que atue com React para contribuir na criação de um novo projeto. Um LMS (learning management system) para o setor de gerenciamento de benefícios empresariais.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/frontreactlms">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                    


                      


                      
                       
                    
                    
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/elixir"> Elixir Developer</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                Buscamos profissionais em Elixir para trabalhar no back-end de um novo produto com uma equipe internacional.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/elixir">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                         */}


                                        <p className="text-muted lead mx-auto">
                                            <strong>Um pouco sobre a UP e nossos times de Desenvolvimentos:</strong><br/><br/>

                        Nossos diretores, formados na área de TI, possuem mais de 20 anos de carreira formando e gerindo equipes em diversos países, hoje com uma forte capacidade de produção de protótipos e construções de times para Startups, majoritariamente do mercado Norte Americano.
                                            <br /><br />
                        Nossas equipes se destacam pelas suas características de colaboração, criatividade e alto desempenho.
                                            <br /><br />
                                            Nosso objetivo principal é levar o <strong>talento</strong> certo ao <strong>projeto</strong> certo.
                                            <br /><br />
                        Adotamos uma gestão baseada na colaboração e fornecemos um ambiente realmente diferenciado de trabalho, buscando incentivar o bem estar e a evolução das pessoas, disponibilizamos uma série de benefícios e programas para fomentar o desenvolvimento contínuo, como:

                                            <br />
                                            <ul>

<li>  Remuneração de acordo com plano de carreiras;</li>
<li>  Um plano de desenvolvimento pessoal que vai ajudar a melhorar as habilidades que estão alinhadas aos projetos;</li>
<li>  Horário de trabalho flexível;</li>
<li>  Aulas de Inglês semanal, em diversos níveis;</li>
<li>   Ajudas de custo para Cursos e Especializações;</li>
<li>    Home Office:</li>
<li> Fornecimento de todo equipamento necessário;</li>
<li> Ajudas de custo para aqueles que utilizam seu próprio equipamento;</li>
<li> Auxílio contábil;</li>
<li> Programa de apoio a saúde mental, entre outros;</li>


</ul>
<br/><br/>
                       
                     
                      </p>

                      {/*
                       * 
                       * 
                       * 
                       * 
                       * 
                       *   <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/frontreactlms">React Frontend</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                Buscamos alguém que atue com React para contribuir na criação de um novo projeto. Um LMS (learning management system) para o setor de gerenciamento de benefícios empresariais.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/frontreactlms">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/ruby">
                                    Ruby Developer -
                                    <strong> Pleno / Senior </strong>
                                  </a>
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Estamos procurando pessoas desenvolvedoras
                                  Ruby on Rails para trabalhar com nossos
                                  projetos norte-americanos.
                                </p>
                                <strong>
                                  <strong>
                                    <a href="/vagas/ruby">Mais Detalhes..</a>
                                  </strong>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                     

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/php">
                                    PHP Developer<strong> Senior </strong>
                                  </a>
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Estamos buscando uma pessoa que tenha
                                  experiência como programador(a) PHP para atuar
                                  no time de um dos nossos projetos
                                  norte-americanos.
                                </p>
                                <strong>
                                  <strong>
                                    <a href="/vagas/php">Mais Detalhes..</a>
                                  </strong>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                      

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/react">React Developer</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Estamos buscando uma pessoa que tenha
                                  experiência com React e/ou React Native para
                                  atuar no time de um dos nossos projetos
                                  norte-americanos da área da Construção Civil,
                                  que está desenvolvendo um software inovador no
                                  setor da construção.
                                </p>

                                <strong>
                                  <strong>
                                    <a href="/vagas/react">Mais Detalhes..</a>
                                  </strong>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>




                     
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/qa">Analista de Qualidade JR</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                Estamos buscando uma pessoa Analista de
                                Qualidade para atuar nos nossos projetos
                                norte-americanos! O seu foco será criar processos
                                de testes e executar os planos para manter o
                                sistema funcional com qualidade.
                                <br />
                                <br />
                                <strong>
                                  <a href="/vagas/qa">Mais Detalhes..</a>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                   
                    */}

                    

                    
                      {/*
                        
                          
                    

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  <a href="/vagas/react">React Developer</a>
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Estamos buscando uma pessoa que tenha
                                  experiência com React e/ou React Native para
                                  atuar no time de um dos nossos clientes
                                  norte-americanos da área da Construção Civil,
                                  que está desenvolvendo um software inovador no
                                  setor da construção.
                                </p>

                                <strong>
                                  <strong>
                                    <a href="/vagas/react">Mais Detalhes..</a>
                                  </strong>
                                </strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>


                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  PHP Developer{" "}
                                  <strong> Pleno / Senior </strong>
                                </div>
                              </div>
                              <div className="card-body ">
                                <strong>Vaga encerrada</strong>
                                <br />
                                <br />
                                Você pode deixar seu perfil disponível para
                                novas oportunidades no nosso{" "}
                                <a href="/bancodetalentos"> Pool de Talentos</a>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                      {/*
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">Quality Analyst</div>
                              </div>
                              <div className="card-body ">
                                <strong>Vaga encerrada</strong>
                                <br />
                                <br />
                                Você pode deixar seu perfil disponível para
                                novas oportunidades no nosso{" "}
                                <a href="/bancodetalentos"> Pool de Talentos</a>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  Ruby on Rails <strong> Senior </strong>{" "}
                                  Developer
                                </div>
                              </div>
                              <div className="card-body ">
                                <strong>Vaga encerrada</strong>
                                <br />
                                <br />
                                Você pode deixar seu perfil disponível para
                                novas oportunidades no nosso{" "}
                                <a href="/bancodetalentos"> Pool de Talentos</a>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  Programador PHP Senior -
                                  <strong> Backend </strong> (Inglês Avançado)
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Esta oportunidade visa a liderança técnica de
                                  um time de desenvolvimento distribuído
                                  remotamente onde a função principal desejada é
                                  a de coordenação de arquitetura do sistema e
                                  suporte constante para o time de
                                  desenvolvimento.
                                </p>
                                <strong>Vaga Encerrada</strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  Programador PHP Pleno / Senior &nbsp;
                                  <strong>Backend</strong>
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Esta oportunidade tem como objetivo a
                                  construção de recursos que envolvem
                                  tecnologias de back-end (servidores, banco de
                                  dados, modelagem e programação) com front-end
                                  Javascript (interface).
                                </p>
                                <strong>Vaga Encerrada</strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-0"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  Engenheiro de Segurança
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  O engenheiro de Segurança vai ser responsável
                                  por testar APIs e APPs utilizando sua
                                  experiência para garantir a segurança dos
                                  serviços contribuindo com o time de
                                  desenvolvimento. A indústria desta empresa
                                  atua em jogos e está localizada na Europa. O
                                  inglês fluente é exigido para conversação com
                                  o time remoto.
                                </p>

                                <strong>Vaga Encerrada</strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  Programador Angular Pleno -{" "}
                                  <strong>FrontEnd</strong>
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Esta oportunidade visa a construção e
                                  manutenção da arquitetura visual de sistemas
                                  (UX/UI) de front-end da indústria de jogos na
                                  Europa. O inglês fluente é exigido para
                                  conversação com o time remoto.
                                </p>

                                <strong>Vaga Encerrada</strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  {" "}
                                  Programador PHP Senior -{" "}
                                  <strong>Backend</strong> (Inglês Fluente)
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Esta oportunidade tem como objetivo a
                                  construção de recursos focados em APIs entre
                                  outras tarefas de back-end em linguagem PHP,
                                  exigindo comunicação constante com o time de
                                  desenvolvimento remoto em inglês. A
                                  comunicação será feita por ferramentas e o
                                  domínio do Inglês é essencial. A indústria
                                  desta empresa atua em jogos e está localizada
                                  na Europa. O inglês fluente é exigido para
                                  conversação com o time remoto.
                                </p>

                                <strong>Vaga Encerrada</strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                {" "}
                                <div className="card-body">
                                  Programador PHP Senior - &nbsp;{" "}
                                  <strong>FrontEnd</strong>
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Esta oportunidade visa a construção e
                                  manutenção da arquitetura de sistemas de
                                  back-end da indústria de jogos na Europa. O
                                  inglês fluente é exigido para conversação com
                                  o time remoto.
                                </p>

                                <strong>Vaga Encerrada</strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                {" "}
                                <div className="card-body">
                                  QA - <strong> Cypress/Automação</strong> de
                                  APIs
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  Esta oportunidade visa o Teste de Qualidade na
                                  indústria de jogos na Europa. O inglês fluente
                                  é exigido para conversação com o time remoto.
                                </p>
                                <strong>Vaga Encerrada</strong>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row>
                       <Row>
                        <Col lg="12" className="mx-lg-auto">
                          <Fade bottom key="1">
                            <div
                              className="card shadow-box shadow-hover mb-3"
                              key="1"
                            >
                              <div className="card-header py-3">
                                <div className="card-body">
                                  PHP Pleno <strong>BackEnd</strong> - Laravel
                                </div>
                              </div>
                              <div className="card-body ">
                                <p>
                                  - 3 anos de experiência ou mais
                                  <br />
                                  - Inglês Avançado (conversação)
                                  <br />
                                  - Experiência com Banco de Dados MySql /
                                  similar
                                  <br />
                                  - Experiência com Symfony ou Laravel
                                  <br />- Conhecimentos com API / REST / XML
                                </p>
                                <a
                                  class="more-link btn btn-outline-alternate more-link mt-0"
                                  href="/vagas/php-plenolaravel"
                                >
                                  Saiba mais...
                                </a>
                              </div>
                            </div>
                          </Fade>
                        </Col>
                      </Row> */}
                                    </div>

                                  
                    {!isMobile && (
                      <div className="col-md-1">
                        <div className="rotated-mockups device-twin">
                          <div className="browser absolute shadow-lg">
                            <img
                              src={require("assets/img/screens/portfolio/resume.png")}
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Container>
            )}
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Career;
