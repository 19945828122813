import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// routing components
//import Index from "views/Index.jsx";
//import Alternative1 from "views/landing/Alternative1.jsx";
//import Alternative2 from "views/landing/Alternative2.jsx";
import Alternative3 from "views/landing/Alternative3.jsx";

// global template script file
import "./laapp.js";
import About from "views/alter3/About.jsx";
import Services from "views/alter3/Services.jsx";
import Team from "views/alter3/Team.jsx";
import Contact from "views/alter3/Contact.jsx";
import Careers from "views/alter3/Career.jsx";
import CompanyValues from "views/alter3/companyvalues.jsx";
import CareersDetails from "views/alter3/Career-details.jsx";
import Elixir from "views/alter3/Elixir.jsx";
// import Clients from "views/alter3/Clients.jsx";
import Linkedin from "views/alter3/Linkedin.jsx";

import ServicesAgileDev from "views/alter3/ServicesAgileDevelopment.jsx";
import ServicesStartup from "views/alter3/ServicesStartup.jsx";
import OutSourcing from "views/alter3/ServicesOutsourcing.jsx";
import Profile from "views/alter3/Profile.jsx";
import Login from "views/alter3/Login.jsx";
import Privacy from "views/alter3/Privacy.jsx";
import BancoDeTalentos from "views/alter3/bancodetalentos.jsx";
import Bauru from "views/alter3/bauru.jsx";
// import Process from "views/alter3/Process.jsx";

import userLocale from "utils/userLocale";
import Sobre from "./views/alter3/Sobre";

const routes = [
  {
    path: '/',
    render: (props) => <Alternative3 {...props} />,
    language: 'en',
    redirect: '/home',
  },
  {
    path: '/home',
    render: (props) => <Alternative3 {...props} />,
    language: 'pt-BR',
    redirect: '/',
  },
  {
    path: '/about',
    component: About,
    language: 'en',
    redirect: '/sobre',
  },
  {
    path: '/sobre',
    component: Sobre,
    language: 'pt-BR',
    redirect: '/about',
  },
  {
    path: '/services',
    component: Services,
    language: 'en',
    redirect: '/servicos',
  },
  {
    path: '/servicos',
    component: Services,
    language: 'pt-BR',
    redirect: '/services',
  },
  {
    path: '/contact',
    component: Contact,
    language: 'en',
    redirect: '/contato',
  },
  {
    path: '/contato',
    component: Contact,
    language: 'pt-BR',
    redirect: '/contact',
  },
  {
    path: '/careers',
    component: Careers,
    language: 'en',
    redirect: '/carreiras'
  },
  {
    path: '/carreiras',
    component: Careers,
    language: 'pt-BR',
    redirect: '/careers'
  },
];

userLocale.loadLanguage().then(userLanguage =>

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {routes.map(route => {
        if (userLanguage != route.language) {
          return (
            <Route path={route.path} exact>
              <Redirect to={route.redirect} />
            </Route>
          )
        }
        return <Route path={route.path} exact render={route.render} component={route.component} language={route.language}/>
      })}


      {/* <Route path="/process" component={Process} /> */}


      <Route path="/companyvalues" exact component={CompanyValues} />

      <Route path="/vagas/fullstackphpsr" exact component={() => <CareersDetails jobpos={`fullstackphpsr`} emailSubj={"mailto:rh@upoutsourcing.com?subject=fullstackphpsr"} />} />
      <Route path="/vagas/backendnode" exact component={() => <CareersDetails jobpos={`backendnode`} emailSubj={"mailto:rh@upoutsourcing.com?subject=backendnode"} />} />
      <Route path="/vagas/frontendreact" exact component={() => <CareersDetails jobpos={`frontendreact`} emailSubj={"mailto:rh@upoutsourcing.com?subject=frontendreact"} />} />
      <Route path="/vagas/virtualassistant" exact component={() => <CareersDetails jobpos={`virtualassistant`} emailSubj={"mai0zlto:rh@upoutsourcing.com?subject=virtualassistant"} />} />
      <Route path="/vagas/backendsenior" exact component={() => <CareersDetails emailSubj={"mailto:rh@upoutsourcing.com?subject=BackendSenior"} jobpos={`backendsenior`} />} />
      <Route path="/vagas/seniorelixir" exact component={()=> <CareersDetails  jobpos={`seniorelixir`} emailSubj={"mailto:rh@upoutsourcing.com?subject=seniorelixir"} />}   />


      {/*
       * 

            

<Route path="/vagas/cms" exact component={()=> <CareersDetails  jobpos={`cms`} emailSubj={"mailto:rh@upoutsourcing.com?subject=CMS"} />}   />
 <Route path="/vagas/php" exact component={()=> <CareersDetails  jobpos={`php`} emailSubj={"mailto:rh@upoutsourcing.com?subject=PHP"} />}   />
     
<Route path="/vagas/ruby" exact component={()=> <CareersDetails  jobpos={`ruby`} emailSubj={"mailto:rh@upoutsourcing.com?subject=Ruby on Rails"} />}   />
      
      <Route path="/vagas/engseg" exact component={()=> <CareersDetails  jobpos={`engseg`} emailSubj={"mailto:rh@upoutsourcing.com?subject=Engenheiro de Seguranca"} />}   />
      <Route path="/vagas/php-senior" exact component={()=> <CareersDetails  emailSubj={"mailto:rh@upoutsourcing.com?subject=Programador PHP Senior - Backend"} jobpos={`phpsenior`} />}   />
      <Route path="/vagas/php-pleno" exact component={()=> <CareersDetails  emailSubj={"mailto:rh@upoutsourcing.com?subject= PHP Pleno BackEnd"} jobpos={`phppleno`} />}   />     
      <Route path="/vagas/php-senior-front"  exact component={()=> <CareersDetails  emailSubj={"mailto:rh@upoutsourcing.com?subject=Programador PHP Senior - FrontEnd"} jobpos={`phpplenofront`} />}   />     
      <Route path="/vagas/php-leader" exact component={()=> <CareersDetails  emailSubj={"mailto:rh@upoutsourcing.com?subject=Programador PHP Senior - Backend"} jobpos={`phpleader`} />}   />      
      <Route path="/vagas/php-plenolaravel" exact component={()=> <CareersDetails  emailSubj={"mailto:rh@upoutsourcing.com?subject=PHP Pleno BackEnd - Laravel"} jobpos={`phpplenolaravel`} />}   />  
      <Route path="/vagas/angular" exact component={()=> <CareersDetails  emailSubj={"mailto:rh@upoutsourcing.com?subject=Programdor Angular Pleno"} jobpos={`angular`} />}   />
      <Route path="/vagas/datascience" exact component={() => <CareersDetails emailSubj={"mailto:rh@upoutsourcing.com?subject=Data Science"} jobpos={`datascience`} />} />
      <Route path="/vagas/phpreact" exact component={()=> <CareersDetails  emailSubj={"mailto:rh@upoutsourcing.com?subject=Programador PHP/React Senior"} jobpos={`react`} />}   />
      <Route path="/vagas/elixir" exact component={() => <CareersDetails emailSubj={"mailto:rh@upoutsourcing.com?subject=Programador Elixir"} jobpos={`elixir`} />} />
*/}
      

      {/* <Route path="/clients" component={Clients} /> */}
            {/* <Route path="/linkedin" component={Linkedin} />*/}

      <Route path="/bancodetalentos" component={BancoDeTalentos} />
      <Route path="/bauru" component={Bauru} />

      <Route path="/agiledevelopment" component={ServicesAgileDev} />
      <Route path="/startup" component={ServicesStartup} />
      <Route path="/outsourcing" component={OutSourcing} />

      <Route path="/elixir" component={Elixir} />



      {/* <Route path="/profile" component={Profile} /> */}
      <Route path="/privacy-policy" component={Privacy} />

            {/*<Route path="/login" component={Login} />*/}

      {/* <Route path="/agileleadership" component={AgileLeadership} /> */}

      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
)

);
